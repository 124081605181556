<template>
  <div class="container">
    <form @submit.prevent="updateOrg" id="updateOrgForm" novalidate>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="orgNameInput" class="form-label float-start">Organization name*</label>
          <input v-bind:class="{'form-control':true, 'is-invalid' : !orgName}" id="orgNameInput" v-model="orgName"
                 placeholder="Name of your organization"
                 required/>
          <div class="invalid-feedback">Organization name is required</div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="orgEmailInput" class="form-label float-start">Organization email*</label>
          <input id="orgEmailInput" v-bind:class="{'form-control':true, 'is-invalid' : !orgEmailValid}"
                 v-on:blur="validateEmail" type="email"
                 v-model="orgEmail" placeholder="Organization email address"
                 required/>
          <div class="invalid-feedback">Email is invalid</div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="countryInput" class="form-label float-start">Country*</label>
          <input id="countryInput" v-bind:class="{'form-control':true, 'is-invalid' : !country}" type="text"
                 v-model="country" placeholder="Country" required/>
          <div class="invalid-feedback">Country is required</div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="stateInput" class="form-label float-start">State or region</label>
          <input class="form-control" id="stateInput" type="text" v-model="state" placeholder="State or region"/>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="cityInput" class="form-label float-start">City</label>
          <input class="form-control" id="cityInput" type="text" v-model="city" placeholder="City"/>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="postalCodeInput" class="form-label float-start">Postal code</label>
          <input class="input form-control" id="postalCodeInput" type="text" v-model="postalCode"
                 placeholder="Postal code"/>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="addressInput" class="form-label float-start">Address</label>
          <input class="input form-control" id="addressInput" type="text" v-model="address" placeholder="Address"/>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="phoneNumberInput" class="form-label float-start">Phone number</label>
          <input class="input form-control" id="phoneNumberInput" type="tel" v-model="phoneNumber"
                 placeholder="Phone number"/>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="taxCodeInput" class="form-label float-start">Tax id</label>
          <input class="input form-control" id="taxCodeInput" type="text" v-model="taxCode" placeholder="Tax id"/>
        </div>
      </div>
      <div>
        <div class="spinner-border" role="status" v-if="submitted">
          <span class="visually-hidden">Loading...</span>
        </div>

        <div v-if="hasValidationErrors" class="errorMessage">
          There are errors in the form. Please review and try again.
        </div>
        <div v-if="errorMessage" class="errorMessage">
          {{ errorMessage }}
        </div>
        <button class="button" v-if="!submitted">Update organization</button>
      </div>
    </form>
  </div>
</template>

<script>

import {AttiniApi} from "@/AttiniApi";

export default {
  name: "UpdateOrganization",
  data() {
    return {
      submitted: false,
      errorMessage: '',
      orgName: '',
      orgEmail: '',
      orgEmailValid: true,
      country: '',
      state: '',
      city: '',
      postalCode: '',
      address: '',
      taxCode: '',
      phoneNumber: '',
      hasValidationErrors: false
    }
  },
  created() {
    this.orgName = this.organization.organizationName;
    this.orgEmail = this.organization.organizationEmail;
    this.country = this.organization.country;
    this.state = this.organization.state;
    this.city = this.organization.city;
    this.postalCode = this.organization.postalCode;
    this.address = this.organization.address;
    this.taxCode = this.organization.taxCode;
    this.phoneNumber = this.organization.phoneNumber;

  },
  props: {
    organization: Object
  },
  methods: {
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.orgEmailValid = re.test(this.orgEmail.toLowerCase());
      return this.orgEmailValid;
    },

    isValid() {
      return this.validateEmail() && this.orgName && this.country;
    },
    async updateOrg() {
      if (!this.isValid()) {
        this.hasValidationErrors = true;
        return;
      }
      this.submitted = true;
      this.errorMessage = false;
      this.hasValidationErrors = false;
      const body = {
        organizationName: this.orgName,
        organizationEmail: this.orgEmail,
        country: this.country,
        state: this.state,
        city: this.city,
        postalCode: this.postalCode,
        address: this.address,
        taxCode: this.taxCode,
        phoneNumber: this.phoneNumber
      };
      AttiniApi.post("organization/update", body)
          .then(() => {
            this.submitted = false;
            this.$emit('editOrg', false);
          })
          .catch(() => {
            this.errorMessage = "Could not update organization, please try again. If the problem persist please contact Attini at support@attini.io"
            this.submitted = false;
          })
    }
  }
}
</script>

<style scoped>
#updateOrgForm > * {
  margin: 30px 0;
}

</style>