<template>
  <div class="container">
    <div v-if="!editOrg">
      <h4>Organization</h4>
      <p>
        View and edit organization information. You can also remove existing users from your organization.
      </p>
      <h5 style="padding-top: 40px">Organization information</h5>
      <ul class="list-group mx-auto justify-content-center"
          v-if=" !isLoadingOrganization && organization.organizationId">
        <li class="list-group-item">
          Name: {{ organization.organizationName }}
        </li>
        <li class="list-group-item">
          Email: {{ organization.organizationEmail }}
        </li>
        <li class="list-group-item">
          Country: {{ organization.country }}
        </li>
        <li class="list-group-item">
          State: {{ organization.state }}
        </li>
        <li class="list-group-item">
          City: {{ organization.city }}
        </li>
        <li class="list-group-item">
          Postal code: {{ organization.postalCode }}
        </li>
        <li class="list-group-item">
          Address: {{ organization.address }}
        </li>
        <li class="list-group-item">
          Phone number: {{ organization.phoneNumber }}
        </li>
        <li class="list-group-item">
          Tax id: {{ organization.taxCode }}
        </li>
      </ul>
      <button class="button" @click="showEditOrg(true)" v-if="organization.organizationEmail">Update organization
      </button>
    </div>
    <div v-if="!isLoadingOrganization && !organization.organizationId">
      Could not find any organization connected to this user. Create one
      <router-link to="/create-organization">here</router-link>
    </div>
    <div class="spinner-border" role="status" v-if="isLoadingOrganization">
      <span class="visually-hidden">Loading...</span>
    </div>
    <UpdateOrganization v-if="editOrg" :organization="organization" @editOrg="refreshOrg"></UpdateOrganization>
    <div style="margin-top: 40px">
      <h5>Current users</h5>
      <div class="spinner-border" role="status" v-if="users.length === 0">
        <span class="visually-hidden">Loading...</span>
      </div>
      <ul class="list-group mx-auto justify-content-center">
        <li class="list-group-item" v-for="user in users" v-bind:key="user.email">
          <div class="row">
            <div class="col">
              <div class="float-start">
                {{ user.email }}
              </div>

            </div>
            <div class="col">
              <div class="spinner-border float-end" role="status" v-if="user.email=== userToDelete && deletingUser">
                <span class="visually-hidden">Loading...</span>
              </div>
              <button type="button" class="btn btn-danger btn-sm float-end" data-bs-toggle="modal"
                      data-bs-target="#confirmDelete"
                      v-if="loggedInUser !== user.email && !(user.email=== userToDelete && deletingUser)"
                      @click="setForDelete(user.email)">Delete
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="modal fade" id="confirmDelete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Confirm</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Are you sure you want to remove user {{ userToDelete }} from {{ organization.organizationName }}?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteUser()">Delete user
            </button>
          </div>
        </div>
      </div>
    </div>
    <invite-user style="padding-top: 40px; margin-bottom: 50px"></invite-user>

  </div>


</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import UpdateOrganization from "@/components/UpdateOrganization";
import InviteUser from "@/views/InviteUser";
import {AttiniApi} from "@/AttiniApi";


export default {
  name: "ManageOrganization",
  components: {InviteUser, UpdateOrganization},
  data() {
    return {
      userToAdd: '',
      userToDelete: '',
      users: [],
      organization: {},
      editOrg: false,
      deletingUser: false,
      isLoadingOrganization: true,

    }
  },
  watch: {
    org: function () {
      this.users = [];
      this.getUsers();
    }
  },
  created() {
    this.loadOrganization();
  },
  setup() {
    const store = useStore();

    let loggedInUser = computed(function () {
      return store.state.email;
    })

    return {loggedInUser}
  },
  methods: {
    async loadOrganization() {
      this.getOrganization().then(() => this.getUsers());
    },
    async getUsers() {
      AttiniApi.get("organization/users").then(value => {
        this.users = value.data;
      })
    },
    async getOrganization() {
      AttiniApi.get("organization")
          .then(value => this.organization = value.data)
          .then(() => this.isLoadingOrganization = false)
          .catch(() => this.isLoadingOrganization = false);
    },
    async refreshOrg() {
      this.getOrganization()
          .then(() => this.showEditOrg(false))
    },
    async showEditOrg(value) {
      this.editOrg = value;
    },
    async setForDelete(email) {
      this.userToDelete = email;
    },
    async deleteUser() {
      this.deletingUser = true;
      AttiniApi.delete("organization/user", {email: this.userToDelete})
          .then(() => this.getUsers().then(() => this.deletingUser = false));
    }
  }
}
</script>

<style scoped>
ul {
  max-width: 500px
}
</style>