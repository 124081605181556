<template>
<div class="container">
  <h4>No such page  &#128565;</h4>
  <p>
    We could not find a page matching the given path.
  </p>
</div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>

</style>