<template>

  <div class="container">
    <h5>Invite a new user</h5>
    <p>
      Invite a new user to your administrator to your organization. An invitation email will be sent with
      a temporary password to the given email address.
    </p>
    <form @submit.prevent="inviteUser" novalidate>
      <div class="row justify-content-md-center">
        <div class="col-md-6 col-md-3">
          <input v-bind:class="{'form-control':true, 'is-invalid' : !emailIsValid}" type="email" v-model="email" placeholder="Email address..."/>
          <div class="invalid-feedback">Email is invalid</div>
        </div>
      </div>
      <div v-if="errorMessage" class="errorMessage">
        {{ errorMessage }}
      </div>
      <div  v-if="!submitted && !isLoading">
        <button class="button">Invite user</button>
      </div>
    </form>
    <div class="spinner-border" role="status" v-if="isLoading" style="margin-top: 20px">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div v-if="submitted">
      Invitation email sent to {{email}}
    </div>
  </div>
</template>

<script>
import {AttiniApi} from "@/AttiniApi";

export default {
  name: "InviteUser",
  data() {
    return {
      email: '',
      emailIsValid: true,
      submitted: false,
      isLoading: false,
      errorMessage: ''
    }
  },
  methods: {
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.emailIsValid = re.test(this.email.toLowerCase());
      return this.emailIsValid;
    },
    async inviteUser() {
      if (!this.validateEmail()){
        return;
      }
      this.isLoading = true;

      AttiniApi.post( "/organization/invite",  {email: this.email})
          .then(() => {
            this.submitted = true;
            this.isLoading = false;
            this.errorMessage = '';
          }).catch(() => {
            this.isLoading = false;
            this.errorMessage = "Could not invite user, please try again later or contact Attini at support@attini.io"
      });
    }
  }

}
</script>

<style scoped>

</style>